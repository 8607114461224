import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';
import './Support.css';


const SupportPage = () => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [screenshot, setScreenshot] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [showResponseMessage, setShowResponseMessage] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setPercentage(0);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('subject', subject);
    formData.append('message', message);
    if (screenshot) {
      formData.append('screenshot', screenshot);
    }

    // Simulate the sending process
    const interval = setInterval(() => {
      setPercentage((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          return 100;
        }
        return prev + 10;
      });
    }, 100);

    const response = await fetch(`/send-report`, {
      method: 'POST',
      body: formData,
    });

    const data = await response.text();
    setResponseMessage(data);

    // Show the response message temporarily
    setShowResponseMessage(true);
    setTimeout(() => {
      setShowResponseMessage(false);
    }, 300);

    // Clear the input fields after submission
    setName('');
    setEmail('');
    setSubject('');
    setMessage('');
    setScreenshot(null);
    document.getElementById('screenshot').value = null;

    setLoading(false);
    setPercentage(0);
  };

  ReactGA.send({ hitType: 'pageview', page:window.location.pathname,title: 'Support Page'})

  return (
  <div className="support-container">
    <div className="support-page">
      <h1 className='support-page__title'>{t('Feedback Form')}</h1>
      <p>{t('Fill in to help us fix issues')}</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group-support">
          <label>{t('Name')}</label>
          <input
            type="text"
            value={name}
            placeholder='How should we call you?'
            onChange={(e) => {
              setName(e.target.value);
              ReactGA.event({category: 'Support', action: "Support report started"});
            }}
            required
          />
        </div>
        <div className="form-group-support">
          <label>{t('Email (optional)')}</label>
          <input
            type="email"
            value={email}
            placeholder='Email so we can contact you back :)'
            onChange={(e) => setEmail(e.target.value)}
            // required
          />
        </div>
        <div className="form-group-support">
          <label>{t('Topic')}</label>
          <input
            type="text"
            placeholder='What is your request about?'
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="form-group-support">
          <label>{t('Message')}</label>
          <textarea
            placeholder='Please describe your issue or request here.'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group-support">
          <label>{t('Screenshot (if needed)')}</label>
          <input
            type="file"
            id="screenshot"
            onChange={(e) => setScreenshot(e.target.files[0])}
          />
        </div>
        {showResponseMessage ? (
          <p className="response-message">{responseMessage}</p>
        ) : (
          <button type="submit" disabled={loading} onClick={ReactGA.event({category:'Support',action:"Support report Submited"})}>
            {loading ? (
              <div className="loading-indicator">
                <div className="loading-circle">{percentage}%</div>
              </div>
            ) : (
              t('Send Report')
            )}
          </button>
        )}
      </form>
    </div>
</div>
  );
};

export default SupportPage;
