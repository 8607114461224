import React, { useEffect, useCallback } from 'react';
import QRCode from 'qrcode-generator';
import './QRGenerator.css';

const QRGenerator = ({ url, qrColor, bgColor, size, shape, qrCodeVisible, isLoading, loadingPercentage, qrRef }) => {
  const generateQRCode = useCallback(() => {
    if (!qrRef.current) return;
    const qr = QRCode(0, 'L');
    qr.addData(url);
    qr.make();

    const canvas = qrRef.current;
    const ctx = canvas.getContext('2d');
    const moduleCount = qr.getModuleCount();
    const moduleSize = size / moduleCount;
    const padding = 20; // Padding to make background larger

    canvas.width = size + padding * 2;
    canvas.height = size + padding * 2;
    canvas.style.width = `${size + padding * 2}px`;
    canvas.style.height = `${size + padding * 2}px`;

    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the background
    ctx.fillStyle = bgColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Draw the QR code
    for (let row = 0; row < moduleCount; row++) {
      for (let col = 0; col < moduleCount; col++) {
        ctx.fillStyle = qr.isDark(row, col) ? qrColor : bgColor;
        if (shape === 'circle' && qr.isDark(row, col)) {
          ctx.beginPath();
          ctx.arc(
            col * moduleSize + moduleSize / 2 + padding,
            row * moduleSize + moduleSize / 2 + padding,
            moduleSize / 2.2,
            0,
            2 * Math.PI
          );
          ctx.fill();
        } else if (qr.isDark(row, col)) {
          ctx.fillRect(
            col * moduleSize + padding,
            row * moduleSize + padding,
            moduleSize + 1,
            moduleSize + 1
          );
        }
      }
    }
  }, [url, qrColor, bgColor, size, shape, qrRef]);

  useEffect(() => {
    if (qrCodeVisible) {
      generateQRCode();
    }
  }, [url, qrColor, bgColor, size, shape, qrCodeVisible, generateQRCode]);

  return (
    <div className="qr-code-display">
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
          <p>Generating QR Code... {loadingPercentage}%</p>
        </div>
      ) : (
        <div className="qr-code">
          <canvas ref={qrRef} id="qrCanvas"></canvas>
        </div>
      )}
    </div>
  );
};

export default QRGenerator;
