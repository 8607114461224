import React, { useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { toPng, toJpeg, toSvg } from 'html-to-image';
import AdsBlock from '../AdsBlock/AdsBlock';
import QRGenerator from '../QRGenerator/QRGenerator';
import CustomizationPanel from '../CustomizationPanel/CustomizationPanel';
import ReactGA from 'react-ga4'; 

import './Main.css';

const MainComponent = () => {
  const [url, setUrl] = useState('');
  const [qrColor, setQrColor] = useState('#4A4A4A');
  const [bgColor, setBgColor] = useState('#FFFFFF');
  const [size, setSize] = useState(256);
  const [shape, setShape] = useState('square');
  const [format, setFormat] = useState('png');
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);
  const qrRef = useRef();


  const comfyColors = [
    '#4A4A4A', '#6A6A6A', '#8A8A8A', '#AAAAAA', '#CACACA', '#EAEAEA',
    '#FF6F61', '#6B5B95', '#88B04B', '#F7CAC9', '#92A8D1', '#955251'
  ];
  

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setQrCodeVisible(false);
    setLoadingPercentage(0);
    ReactGA.event({category: 'Main', action: "Started QR Generation"});
    

    const interval = setInterval(() => {
      setLoadingPercentage((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setQrCodeVisible(true);
          setIsLoading(false);
          return 100;
        }
        return prev + 20;
      });
    }, 1000); // Update percentage every second
  };

  const handleDownload = useCallback(() => {
    ReactGA.event({category: 'Main', action: `Downloaded QR ${format}`});
    if (qrRef.current === null) {
      return;
    }

    

    const downloadFn = format === 'jpeg' ? toJpeg : format === 'svg' ? toSvg : toPng;
    const backgroundColor = format === 'png' ? 'transparent' : bgColor;

    downloadFn(qrRef.current, { cacheBust: true, backgroundColor })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `qr-code.${format}`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.error('Failed to download image', err);
      });
  }, [format, bgColor]);

  const handleSizeChange = (newSize) => {
    ReactGA.event({category: 'Main', action: `Changed QR Size to ${newSize}`});
    setSize(newSize);
  };

  ReactGA.send({ hitType: 'pageview', page:window.location.pathname,title: 'Main Page'})


  return (
    <div className="main-container">
      <Helmet>
        <title>QR Code Generator - Create and Download QR Codes</title>
        <meta name="description" content="Generate customizable QR codes for free without registration. Change colors, shapes, and download in various formats." />
        <meta name="keywords" content="QR code,without registration, generator, free, customizable, download, png, jpeg, svg" />
      </Helmet>
      <QRGenerator
        url={url}
        qrColor={qrColor}
        bgColor={bgColor}
        size={size}
        shape={shape}
        qrCodeVisible={qrCodeVisible}
        isLoading={isLoading}
        loadingPercentage={loadingPercentage}
        qrRef={qrRef}
      />
      <CustomizationPanel
        url={url}
        setUrl={setUrl}
        qrColor={qrColor}
        setQrColor={setQrColor}
        bgColor={bgColor}
        setBgColor={setBgColor}
        size={size}
        handleSizeChange={handleSizeChange}
        shape={shape}
        setShape={setShape}
        format={format}
        setFormat={setFormat}
        handleSubmit={handleSubmit}
        handleDownload={handleDownload}
        comfyColors={comfyColors}
      />
      <AdsBlock />
    </div>
  );
};

export default MainComponent;
