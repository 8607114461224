import React from 'react';
import './CustomizationPanel.css';
import rgbIcon from '../../../assets/QR-Quick-palette.png'; 
import ReactGA from 'react-ga4';


const CustomizationPanel = ({
  url,
  setUrl,
  qrColor,
  setQrColor,
  bgColor,
  setBgColor,
  size,
  handleSizeChange,
  shape,
  setShape,
  format,
  setFormat,
  handleSubmit,
  handleDownload,
  comfyColors
}) => {
  const qrRgbPickerRef = React.useRef();
  const bgRgbPickerRef = React.useRef();
  const isMobile = window.innerWidth <= 768;
  const sizeOptions = isMobile ? [64, 128, 256] : [64, 128, 256, 512, 625];

  const handleRgbPickerClick = (pickerRef) => {
    if (pickerRef.current) {
      pickerRef.current.click();
    }
  };

  return (
    <div className="customization-settings">
      <form onSubmit={handleSubmit} id="settingsForm" className='customization-settings-form'>
        <div className="form-group-panel">
          <label htmlFor="url">Enter URL:</label>
          <input type="text" id="url" placeholder='Paste your link here and press Generate QR' className='link_input' value={url} onChange={(e) => setUrl(e.target.value)} required />
        </div>
        <div className="form-group-panel">
          <label>Select QR Code Color:</label>
          <div className="color-palette">
  {comfyColors.map((color) => (
    <div
      key={color}
      className={`color-swatch ${qrColor === color ? 'active' : ''}`}
      style={{ backgroundColor: color }}
      onClick={() => setQrColor(color)}
    />
  ))}
  <div className="color-swatch rgb-button" onClick={() => handleRgbPickerClick(qrRgbPickerRef)}>
    <img src={rgbIcon} alt="RGB" className="rgb-icon" />
  </div>
  <input
    type="color"
    value={qrColor}
    onChange={(e) => {
      setQrColor(e.target.value)
      ReactGA.event({category: 'Main', action: "User changed QR color"});
    }}
    ref={qrRgbPickerRef}
    className="rgb-picker"
  />
</div>
        </div>
        <div className="form-group-panel">
          <label>Select QR Code Background Color:</label>
          <div className="color-palette">
  {comfyColors.map((color) => (
    <div
      key={color}
      className={`color-swatch ${bgColor === color ? 'active' : ''}`}
      style={{ backgroundColor: color }}
      onClick={() => {
        setBgColor(color)
        ReactGA.event({category: 'Main', action: "User changed background QR color"});
      }}
    />
  ))}
  <div className="color-swatch rgb-button" onClick={() => {
      handleRgbPickerClick(bgRgbPickerRef)
      ReactGA.event({category: 'Main', action: "User clicked RGB picker button"});
    }}>
    <img src={rgbIcon} alt="RGB" className="rgb-icon" />
  </div>
  <input
    type="color"
    value={bgColor}
    onChange={(e) => setBgColor(e.target.value)}
    ref={bgRgbPickerRef}
    className="rgb-picker"
  />
</div>
        </div>
        <div className="form-group-panel">
          <label>Select Size:</label>
          <input
            type="range"
            id="size"
            value={size}
            onChange={(e) => handleSizeChange(Number(e.target.value))}
            min="64"
            max= {sizeOptions[sizeOptions.length - 1]}
          />
          <div className="size-buttons">
            {sizeOptions.map((value) => (
              <button
                type="button"
                key={value}
                className={`size-button ${size === value ? 'active' : ''}`}
                onClick={() => handleSizeChange(value)}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
                <div className="form-group-panel">
          <label>Select Shape:</label>
          <div className="button-group">
            <button
              type="button"
              className={`shape-button ${shape === 'square' ? 'active' : ''}`}
              onClick={() => {
                setShape('square')
                ReactGA.event({category: 'Main', action: `User changed QR shape to ${shape}`});
              }}
            >
              <span className="shape-icon square-icon"></span>
              Square
            </button>
            <button
              type="button"
              className={`shape-button ${shape === 'circle' ? 'active' : ''}`}
              onClick={() => setShape('circle')}
            >
              <span className="shape-icon circle-icon"></span>
              Circle
            </button>
          </div>
        </div>
        <div className="form-group-panel">
          <label>Select Format:</label>
          <div className="button-group">
            <button
              type="button"
              className={`format-button ${format === 'png' ? 'active' : ''}`}
              onClick={() => setFormat('png')}
            >
              PNG
            </button>
            <button
              type="button"
              className={`format-button ${format === 'jpeg' ? 'active' : ''}`}
              onClick={() => setFormat('jpeg')}
            >
              JPEG
            </button>
            <button
              type="button"
              className={`format-button ${format === 'svg' ? 'active' : ''}`}
              onClick={() => setFormat('svg')}
            >
              SVG
            </button>
          </div>
        </div>
        <div className="buttons-container">
          <button type="submit" className="generate-button">Generate QR Code</button>
          <button type="button" className="download-button" onClick={handleDownload}>
            Download QR Code
          </button>
        </div>
      </form>
    </div>
  );
};

export default CustomizationPanel;
