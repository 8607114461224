import React from 'react';

import './Main.css';


function Footer() {
  return (
    <footer>
      <p className='footer_text'>© 2024</p>
    </footer>
  );
}

export default Footer;