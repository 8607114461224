import { useEffect } from 'react';

const useToggleNav = (navId, triggerSelector, navItemSelector) => {
  useEffect(() => {
    const mainNav = document.getElementById(navId);

    const handleClick = (event) => {
      if (event.target.matches(`${triggerSelector}, ${triggerSelector} *`)) {
        if (mainNav) {
          mainNav.classList.toggle('nav-open');
        }
      } else {
        if (mainNav.classList.contains('nav-open')) {
          mainNav.classList.remove('nav-open');
        }
      }
    };

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [navId, triggerSelector, navItemSelector]);
};

export default useToggleNav;
