import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./common/Header/Main/Main";
import Footer from "./common/Footer/Main/Main";
import Main from "./pages/Main/Main/Main";
import DonatePage from "./pages/DonatePage/Main/DonatePage";
import SupportPage from "./pages/Support/Main/Support";

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/donate" element={<DonatePage />} />
          <Route path="/support" element={<SupportPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
