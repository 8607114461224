import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../../assets/QR-Quick_logo.png';
import './Main.css';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';


function Header() {
  
  return (
    <header className='header_container'>
      <nav className='header_nav' id='main-nav'>
        <div className='header_name'>
          <img src={logo} alt='logo' className='header_logo'></img>
          <h1 className='header_title centered'>QR Quick</h1>
        </div>
        <HamburgerMenu />
        <ul className='header_nav-list'>
          <li>
            <NavLink to='/' exact className='header_nav-element' activeClassName='active'>
              QR Generator
            </NavLink>
          </li>
          <li>
            <NavLink to='/donate' className='header_nav-element' activeClassName='active'>
              Donate
            </NavLink>
          </li>
          <li>
            <NavLink to='/support' className='header_nav-element' activeClassName='active'>
              Support
            </NavLink>
          </li>
        </ul>

      </nav>
    </header>
  );
}

export default Header;
