import React from 'react';
import { NavLink } from 'react-router-dom';
import './HamburgerMenu.css';
import useToggleNav from '../../../hooks/useToggleHook';

function HamburgerMenu(){
    useToggleNav('main-nav', '.nav-menu','.header_nav-element');


    return (
    <div className='hambuger_menu'>
        <div className="nav-menu">
            <span className="nav-menu-item">Menu</span>
            <span className="nav-menu-cross"></span>
        </div>
      <div className="nav-menu-side">
        <nav className="nav-menu-side-nav">
          <ul>
          <li>
            <NavLink to='/' className= "side-nav-link"  activeClassName='active'>
              QR Generator
            </NavLink>
          </li>
          <li>
            <NavLink  to='/donate' className= "side-nav-link"  activeClassName='active'>
              Donate
            </NavLink>
          </li>
          <li>
            <NavLink  to='/support' className= "side-nav-link"  activeClassName='active'>
              Support
            </NavLink>
          </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default HamburgerMenu;