import React, { useEffect } from 'react';
import './AdsBlock.css';

const AdsBlock = () => {
  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);

  return (
    <div className="ads-block">
      <p className="ads-message">The ads help us to pay for hosting</p>
      <div className="ads-container">
        {/* Google Ads Code */}
        <ins className="adsbygoogle"
             style={{ display: 'block' }}
             data-ad-client="ca-pub-XXXXXXXXXXXXXXXX" // Replace with your AdSense client ID
             data-ad-slot="XXXXXXXXXX" // Replace with your AdSense slot ID
             data-ad-format="auto"
             data-full-width-responsive="true"></ins>
      </div>
    </div>
  );
};

export default AdsBlock;
