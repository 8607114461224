import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import ReactGA from 'react-ga4';
import './DonatePage.css';

const stripePromise = loadStripe('YOUR_PUBLISHABLE_KEY'); // Replace with your Stripe publishable key

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState(500); // Default amount in cents
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { error, paymentIntent } = await stripe.createPaymentIntent({
      amount,
      currency: 'usd',
    });

    if (error) {
      setMessage(`Payment failed: ${error.message}`);
    } else {
      setMessage('Payment successful!');
    }
  };

  ReactGA.send({ hitType: 'pageview', page:window.location.pathname,title: 'Donate Page'})

  return (
    <form classname= 'donate-page__form'onSubmit={handleSubmit}>
      <label className="donate-page__label">
        Donation Amount:
        <input
          type="number"
          value={amount / 100}
          onChange={(e) => {
            setAmount(e.target.value * 100)
            ReactGA.event({category: 'Donate', action: "User changed donation amount"});
          }}
          className="donate-page__input"
        />
      </label>
      <CardElement className="donate-page__card-element" />
      <button type="submit" disabled={!stripe || !elements} className="donate-page__button" onClick={ReactGA.event({category: 'Donate', action: "User clicked Donate button"})}>
        Donate
      </button>
      {message && <div className="donate-page__message">{message}</div>}
    </form>
  );
};

const DonatePage = () => {
  return (
    <div className="donate-page">
      <div className='donate-page__container'>
        <h1 className="donate-page__title">Support Us</h1>
        <p className="donate-page__description">Your donations help us keep the service running and improve it further.</p>
        <p className='donate-page__description  accent'>Thank you for your support!</p>
        <Elements stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
    </div>
  );
};

export default DonatePage;
